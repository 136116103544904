import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Go from "../pages/Go";
import Community from "../pages/Community";
import AIAssistant from "../pages/AIAssistant";
import AirdropPage from "../pages/airdrop";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/go",
    element: <Go />,
  },
  {
    path: "/community",
    element: <Community />,
  },
  {
    path: "/airdrop",
    element: <AirdropPage />,
  
    // icon: <Gift className="w-4 h-4 mr-2" />,
  },
  {
    path: "/assistant",    // Add this route
    element: <AIAssistant />,
  }
]);

export default router; 