import React, { createContext, useContext, useState } from 'react';

const AIContext = createContext();

export const useAIContext = () => useContext(AIContext);

export const AIProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const sendMessage = async (message, preferredAI = 'gpt') => {
    setIsLoading(true);
    try {
      // Call your backend API instead
      const response = await fetch('/api/ai/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message,
          preferredAI
        }),
      });

      const data = await response.json();
      return data.response;

    } catch (error) {
      console.error('AI Error:', error);
      return "I'm sorry, I encountered an error. Please try again.";
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AIContext.Provider value={{ sendMessage, isLoading }}>
      {children}
    </AIContext.Provider>
  );
}; 