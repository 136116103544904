import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { MessageCircle, Send, User, Bot, Mic, MicOff } from 'lucide-react';
import mapboxgl from 'mapbox-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// Dummy location data
const dummyLocation = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1300, -35.2809] },
        "properties": { "name": "Commonwealth Park", "icon": "star" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1543, -35.3084] },
        "properties": { "name": "Red Hill Lookout", "icon": "diamond" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1364, -35.2820] },
        "properties": { "name": "National Gallery of Australia", "icon": "circle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1291, -35.3012] },
        "properties": { "name": "Old Parliament House Gardens", "icon": "square" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1178, -35.2875] },
        "properties": { "name": "Australian National University", "icon": "triangle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1346, -35.2930] },
        "properties": { "name": "Questacon - Science & Technology Centre", "icon": "star" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1489, -35.2815] },
        "properties": { "name": "Manuka Oval", "icon": "diamond" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1221, -35.2769] },
        "properties": { "name": "Black Mountain Nature Reserve", "icon": "circle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1332, -35.2743] },
        "properties": { "name": "Lake Burley Griffin", "icon": "square" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1397, -35.3108] },
        "properties": { "name": "Canberra Glassworks", "icon": "triangle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1420, -35.2846] },
        "properties": { "name": "National Museum of Australia", "icon": "star" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1651, -35.2879] },
        "properties": { "name": "Mount Ainslie Lookout", "icon": "diamond" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1258, -35.2923] },
        "properties": { "name": "Australian War Memorial", "icon": "circle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1384, -35.2703] },
        "properties": { "name": "National Zoo & Aquarium", "icon": "square" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1280, -35.2906] },
        "properties": { "name": "Civic Square", "icon": "triangle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1502, -35.2765] },
        "properties": { "name": "Lennox Gardens", "icon": "star" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1340, -35.2927] },
        "properties": { "name": "Kingston Foreshore", "icon": "diamond" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1129, -35.2986] },
        "properties": { "name": "Belconnen Town Centre", "icon": "circle" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1453, -35.2811] },
        "properties": { "name": "Canberra Theatre Centre", "icon": "square" }
      },
      {
        "type": "Feature",
        "geometry": { "type": "Point", "coordinates": [149.1408, -35.3061] },
        "properties": { "name": "Tuggeranong Arts Centre", "icon": "triangle" }
      }
    ]
  };

// MapBox token
const MAPBOX_TOKEN = 'pk.eyJ1IjoiY2FodW50ZXIiLCJhIjoiY20zcDk3MWptMGQ2OTJyb2FjcWVodXcwbyJ9.KpVXU9HKAb6zv5As2_1BuQ';

// Styled Components
const MainContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  height: 100vh;
  background: #f5f5f5;
`;

const MapContainer = styled.div`
  flex: 1;
  height: calc(100vh - 40px);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChatContainer = styled.div`
  width: 400px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChatHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Message = styled.div`
  padding: 0.75rem;
  border-radius: 8px;
  max-width: 80%;
  ${props => props.isUser ? `
    background-color: #3b82f6;
    color: white;
    align-self: flex-end;
  ` : `
    background-color: #f3f4f6;
    color: black;
    align-self: flex-start;
  `}
`;

const InputArea = styled.div`
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  gap: 0.5rem;
  position: relative;
`;

const Input = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  outline: none;
  &:focus {
    border-color: #3b82f6;
  }
`;

const SendButton = styled.button`
  padding: 0.5rem 1rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    background: #2563eb;
  }
`;

const VoiceButton = styled.button`
  padding: 0.5rem;
  background: ${props => props.isListening ? '#ef4444' : '#3b82f6'};
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.isListening ? '#dc2626' : '#2563eb'};
  }

  &:disabled {
    background: #9ca3af;
    cursor: not-allowed;
  }
`;

const VoiceIndicator = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
`;

const CathAssistant = () => {
  const [messages, setMessages] = useState([
    { role: 'assistant', content: "Hi, I am CAiTH. How could I help to hunt the treasures around?" }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const mapRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [highlightedMarkers, setHighlightedMarkers] = useState([]);
  const markersRef = useRef({});
  const [nearestTreasures, setNearestTreasures] = useState([]);
  const [directionsControl, setDirectionsControl] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [speechSupported, setSpeechSupported] = useState(false);
  const recognitionRef = useRef(null);

  // Add CSS for blinking effect
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .custom-marker {
        transition: all 0.3s ease;
      }
      .marker-highlight {
        animation: blink 1s infinite;
        transform: scale(1.3);
        z-index: 1;
      }
      @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0.4; }
        100% { opacity: 1; }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  // Initialize map and handle markers
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [149.1300, -35.2809],
     zoom:11

    });

    mapRef.current = map;

    // Create object to store marker references
    const markerRefs = {};

    map.on('load', () => {
      dummyLocation.features.forEach(location => {
        const markerEl = document.createElement('div');
        markerEl.className = 'custom-marker';
        markerEl.style.width = '20px';
        markerEl.style.height = '20px';
        markerEl.style.borderRadius = location.properties.icon === 'circle' ? '50%' : '0';
        markerEl.style.backgroundColor = getMarkerColor(location.properties.icon);
        markerEl.style.border = '2px solid white';
        markerEl.style.boxShadow = '0 0 4px rgba(0,0,0,0.3)';

        const popup = new mapboxgl.Popup({ offset: 25 })
          .setHTML(`<h3>${location.properties.name}</h3>`);

        const marker = new mapboxgl.Marker({ element: markerEl })
          .setLngLat(location.geometry.coordinates)
          .setPopup(popup)
          .addTo(map);

        // Store marker reference with location name as key
        markerRefs[location.properties.name] = markerEl;
      });

      // Store marker refs in ref for later access
      markersRef.current = markerRefs;

      // Fit bounds to show all markers
      const bounds = new mapboxgl.LngLatBounds();
      dummyLocation.features.forEach(location => {
        bounds.extend(location.geometry.coordinates);
      });
      map.fitBounds(bounds, { padding: 50 });
    });

    return () => map.remove();
  }, []);

  // Helper function to get marker colors
  const getMarkerColor = (icon) => {
    const colors = {
      star: '#FFD700',
      diamond: '#FF1493',
      circle: '#32CD32',
      square: '#4169E1',
      triangle: '#FF4500'
    };
    return colors[icon] || '#FF0000';
  };

  const calculateDistance = (point1, point2) => {
    const [lon1, lat1] = point1;
    const [lon2, lat2] = point2;
    
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in km
  };

  const findNearestTreasures = (userLocation, count = 3) => {
    console.log('Finding nearest treasures with user location:', userLocation);
    const treasures = dummyLocation.features
      .map(treasure => {
        const distance = calculateDistance(
          [userLocation.lng, userLocation.lat],
          treasure.geometry.coordinates
        );
        console.log(`Distance to ${treasure.properties.name}:`, distance);
        return {
          ...treasure,
          distance
        };
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, count);
    
    console.log('Found nearest treasures:', treasures);
    return treasures;
  };

  // Add function to highlight markers
  const highlightNearestMarkers = (nearestTreasures) => {
    // Remove previous highlights
    highlightedMarkers.forEach(name => {
      const markerEl = markersRef.current[name];
      if (markerEl) {
        markerEl.classList.remove('marker-highlight');
      }
    });

    // Add new highlights
    const newHighlights = nearestTreasures.map(t => t.properties.name);
    newHighlights.forEach(name => {
      const markerEl = markersRef.current[name];
      if (markerEl) {
        markerEl.classList.add('marker-highlight');
      }
    });

    setHighlightedMarkers(newHighlights);
  };

  // Add navigation functionality
  const startNavigation = (destination) => {
    const map = mapRef.current;
    if (!map || !currentLocation) return;

    // Remove existing directions if any
    if (directionsControl) {
      map.removeControl(directionsControl);
    }

    // Create new directions control
    const directions = new MapboxDirections({
      accessToken: MAPBOX_TOKEN,
      unit: 'metric',
      profile: 'mapbox/walking',
      controls: {
        inputs: false,
        instructions: true
      }
    });

    // Add to map
    map.addControl(directions, 'top-left');
    setDirectionsControl(directions);

    // Set route
    directions.setOrigin([currentLocation.lng, currentLocation.lat]);
    directions.setDestination(destination.geometry.coordinates);

    // Fit map to show entire route
    const bounds = new mapboxgl.LngLatBounds()
      .extend([currentLocation.lng, currentLocation.lat])
      .extend(destination.geometry.coordinates);
    map.fitBounds(bounds, { padding: 100 });

    // Add response message
    const responseMessage = {
      role: 'assistant',
      content: `I'm guiding you to ${destination.properties.name}. Follow the route on the map!`
    };
    setMessages(prev => [...prev, responseMessage]);
  };

  // Update handleNearestTreasuresFound to store nearest treasures
  const handleNearestTreasuresFound = (userLoc) => {
    console.log('Handling nearest treasures with location:', userLoc);
    const nearest = findNearestTreasures(userLoc);
    console.log('Setting nearest treasures:', nearest);
    setNearestTreasures(nearest);
    highlightNearestMarkers(nearest);
    
    const responseMessage = {
      role: 'assistant',
      content: `I found the three nearest treasures for you:\n${
        nearest.map((t, i) => 
          `${i + 1}. ${t.properties.name} (${t.distance.toFixed(2)} km away)`
        ).join('\n')
      }`
    };
    console.log('Adding response message:', responseMessage);
    setMessages(prev => [...prev, responseMessage]);
  };

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = 'en-US';

      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInputMessage(transcript);
        handleSendMessage(transcript);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
      };

      setSpeechSupported(true);
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
      }
    };
  }, []);

  // Add speech synthesis
  const speak = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'en-US';
      utterance.rate = 1;
      utterance.pitch = 1;
      window.speechSynthesis.speak(utterance);
    }
  };

  // Update handleSendMessage to include voice response
  const handleSendMessage = (voiceInput = null) => {
    const messageText = voiceInput || inputMessage;
    if (!messageText.trim()) return;

    const userMessage = {
      role: 'user',
      content: messageText
    };
    setMessages(prev => [...prev, userMessage]);

    const command = messageText.toLowerCase();
    
    // Add voice response for assistant messages
    const respondWithVoice = (responseMessage) => {
      setMessages(prev => [...prev, responseMessage]);
      speak(responseMessage.content);
    };

    if (command.includes('find me the nearest treasures')) {
      if (!currentLocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLoc = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            setCurrentLocation(userLoc);
            handleNearestTreasuresFound(userLoc);
          },
          (error) => {
            const errorMessage = {
              role: 'assistant',
              content: "I couldn't get your location. Please make sure location services are enabled."
            };
            respondWithVoice(errorMessage);
          }
        );
      } else {
        handleNearestTreasuresFound(currentLocation);
      }
    } else if (command.includes('guide me to the nearest one')) {
      if (nearestTreasures.length > 0) {
        startNavigation(nearestTreasures[0]);
      } else {
        const errorMessage = {
          role: 'assistant',
          content: "Please first ask me to find the nearest treasures!"
        };
        respondWithVoice(errorMessage);
      }
    }

    setInputMessage('');
  };

  // Add voice control handlers
  const toggleVoiceRecognition = () => {
    if (isListening) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  // Add cleanup for directions control
  useEffect(() => {
    return () => {
      if (directionsControl && mapRef.current) {
        mapRef.current.removeControl(directionsControl);
      }
    };
  }, [directionsControl]);

  return (
    <MainContainer>
      <MapContainer id="map" />
      <ChatContainer>
        <ChatHeader>
          <MessageCircle size={24} />
          <span>Treasure Hunt Assistant</span>
        </ChatHeader>
        <ChatMessages>
          {messages.map((message, index) => (
            <Message key={index} isUser={message.role === 'user'}>
              {message.content}
            </Message>
          ))}
        </ChatMessages>
        <InputArea>
          <Input
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Type your message..."
          />
          {speechSupported && (
            <VoiceButton
              onClick={toggleVoiceRecognition}
              isListening={isListening}
              title={isListening ? 'Stop recording' : 'Start recording'}
            >
              {isListening ? <MicOff size={20} /> : <Mic size={20} />}
            </VoiceButton>
          )}
          <SendButton onClick={() => handleSendMessage()}>
            <Send size={20} />
          </SendButton>
          {isListening && (
            <VoiceIndicator>Listening...</VoiceIndicator>
          )}
        </InputArea>
      </ChatContainer>
    </MainContainer>
  );
};

export default CathAssistant;
