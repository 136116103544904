import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { ProposalProvider } from './context/ProposalContext';
import { WalletProvider } from './context/WalletContext';
import { AIProvider } from './context/AIContext';

function App() {
  return (
 
      <WalletProvider>
        <ProposalProvider>
          <AIProvider>
            <RouterProvider router={router} />
          </AIProvider>
        </ProposalProvider>
      </WalletProvider>
   
  );
}

export default App;
