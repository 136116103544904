import React from 'react';
import AirdropTasks from '../components/AirdropTasks';
// import Navbar from '../components/Navbar';

const AirdropPage = () => {
  return (
    <div className="min-h-screen bg-background">
      {/* <Navbar /> */}
      <div className="container mx-auto py-8">
        <AirdropTasks />
      </div>
    </div>
  );
};

export default AirdropPage; 