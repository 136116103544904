import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";

const DescriptionContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: 'Press Start 2P', cursive;
    padding: 2rem 0;
    
    @media (max-width: 768px) {
        padding: 4rem 1rem;
        height: auto;
        width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    }
`;

// Animation variants for fade-in effect
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      delay: 1.5
    }
  }
};

// Convert regular styled components to motion components
const ContentWrapper = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    gap: 2rem;
    
    @media (max-width: 1024px) {
        width: 95%;
        gap: 1.5rem;
    }
    
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 3rem;
    }
`;

const LeftSection = styled(motion.div)`
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        align-items: center;
    }
`;

const RightSection = styled(motion.div)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const PreHeading = styled(motion.div)`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #FFDE4D;
    
    @media (max-width: 768px) {
        justify-content: center;
        margin-bottom: 0.8rem;
    }
`;

const Heading = styled(motion.h1)`
    font-size: 5rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 500;
    
    @media (max-width: 1024px) {
        font-size: 4rem;
    }
    
    @media (max-width: 768px) {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    
    @media (max-width: 480px) {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
`;

const Description = styled(motion.p)`
    font-size: 1rem;
    line-height: 2;
    color: #666;
    font-weight: 100;
    font-family: monospace !important;
    
    @media (max-width: 1024px) {
        font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.8;
        text-align: center;
    }
    
    @media (max-width: 480px) {
        font-size: 0.8rem;
        line-height: 1.6;
    }
`;

const Video = styled(motion.video)`
    width: 100%;
    height: 50vh;
    max-width: 500px;
    min-height: 50vh;
    border-radius: 8px;
    object-fit: cover;
    
    @media (max-width: 768px) {
        height: 40vh;
        min-height: 40vh;
        max-width: 100%;
    }
    
    @media (max-width: 480px) {
        height: 30vh;
        min-height: 30vh;
    }
`;

const TopBlur = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none; /* Allow clicks to pass through the blur */
    z-index: 1; /* Place blur beneath the content */
    
    @media (max-width: 768px) {
        height: 150px;
    }
    
    @media (max-width: 480px) {
        height: 100px;
    }
`;

const Line = styled.div`
    width: 48px;
    height: 5px;
    background-color: #FFDE4D;
    
    @media (max-width: 480px) {
        width: 32px;
        height: 3px;
    }
`;

const GetStartedText = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    
    @media (max-width: 1024px) {
        font-size: 1.8rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
`;

const Readmore = styled(motion.a)`
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFDE4D;
    text-align: right;
    width: 100%;
    margin-top: 2rem;
    
    @media (max-width: 1024px) {
        font-size: 1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-top: 1.5rem;
        text-align: center;
    }
    
    @media (max-width: 480px) {
        font-size: 1rem;
        margin-top: 1rem;
        letter-spacing: 1px;
    }
`;

const SocialFi = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    
    if (videoElement) {
      // Only play when the video is loaded
      videoElement.addEventListener('loadeddata', () => {
        if (videoElement.readyState >= 3) {
          videoElement.play().catch(error => {
            console.log("Video autoplay failed:", error);
          });
        }
      });
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadeddata', () => {});
      }
    };
  }, []);

  return (
    <DescriptionContainer>
        <TopBlur />
        <ContentWrapper
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <LeftSection variants={fadeInUp}>
            <PreHeading variants={fadeInUp}>
              <Line />
              <GetStartedText>More than A Game</GetStartedText>
            </PreHeading>
            <Heading variants={fadeInUp}>SocialFi for the disabilities</Heading>
            <Description variants={fadeInUp}>
              1. Placement by placement, we will be able to build many real-world hubs for not only having fun but also building a new social media to connect people with disabilities and the elderly back into mainstream society.<br />
              2. Let the disabled and elderly embrace the web3 and make a living from the web3.<br />
              3. Outddor activities should not be only for the young and healthy.
            </Description>
            <Readmore variants={fadeInUp}>[More Info Soon]</Readmore>
          </LeftSection>
          <RightSection variants={fadeInUp}>

            {/* <Video 
              ref={videoRef}
              loop 
              muted 
              playsInline
              variants={fadeInUp}
            >
              <source src="/video/socialfi.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </Video> */}
          </RightSection>
        </ContentWrapper>
    </DescriptionContainer>
  )
}

export default SocialFi