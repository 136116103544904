import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { 
  Trophy, Map, Compass, MessageSquare, Wallet,
  Camera, Share, Users, Target, Star, 
  Navigation, Heart, Clock, Gift
} from 'lucide-react';

// Styled Components
const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const StyledCard = styled.div`
  background: linear-gradient(to right, rgba(168, 85, 247, 0.1), rgba(59, 130, 246, 0.1));
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.div`
  padding: 1.5rem;
`;

const CardTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CardContent = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const TaskGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GroupTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TaskCard = styled.div`
  background: ${props => props.isCompleted ? 'rgba(34, 197, 94, 0.1)' : 'white'};
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const TaskContent = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TaskInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const IconWrapper = styled.div`
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const TaskTitle = styled.h3`
  font-weight: 500;
`;

const TaskDescription = styled.p`
  font-size: 0.875rem;
  color: #666;
`;

const ProgressContainer = styled.div`
  margin-top: 0.5rem;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background: #3b82f6;
  width: ${props => props.value}%;
  transition: width 0.3s ease;
`;

const ProgressText = styled.span`
  font-size: 0.75rem;
  color: #666;
`;

const TaskActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Badge = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
  font-weight: 600;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background: ${props => props.disabled ? '#ccc' : '#3b82f6'};
  color: white;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background: ${props => props.disabled ? '#ccc' : '#2563eb'};
  }
`;

const AirdropTasks = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [dailyTasks, setDailyTasks] = useState({});
  const [treasuresFound, setTreasuresFound] = useState(0);
  const [photosShared, setPhotosShared] = useState(0);

  const taskGroups = [
    {
      title: "Basic Tasks",
      tasks: [
        {
          id: "base-1",
          title: "Connect Wallet",
          points: 10,
          icon: <Wallet className="w-5 h-5" />,
          description: "Connect your wallet to start earning points",
          type: "wallet"
        },
        {
          id: "base-2",
          title: "Join Community",
          points: 20,
          icon: <MessageSquare className="w-5 h-5" />,
          description: "Join our Discord server and say hi in #general",
          type: "social"
        },
        {
          id: "base-3",
          title: "Complete Tutorial",
          points: 30,
          icon: <Target className="w-5 h-5" />,
          description: "Complete the treasure hunting tutorial",
          type: "tutorial"
        }
      ]
    },
    {
      title: "Daily Hunting Tasks",
      tasks: [
        {
          id: "daily-1",
          title: "Daily Check-in",
          points: 5,
          icon: <Clock className="w-5 h-5" />,
          description: "Check in daily to earn points",
          type: "daily"
        },
        {
          id: "daily-2",
          title: "Find Treasures",
          points: 10,
          icon: <Compass className="w-5 h-5" />,
          description: "Find at least 3 treasures today",
          type: "daily"
        },
        {
          id: "daily-3",
          title: "Share Journey",
          points: 15,
          icon: <Share className="w-5 h-5" />,
          description: "Share your treasure hunting journey on social media",
          type: "daily"
        }
      ]
    },
    {
      title: "Achievement Tasks",
      tasks: [
        {
          id: "achieve-1",
          title: "Treasure Hunter",
          points: 200,
          icon: <Trophy className="w-5 h-5" />,
          description: "Find 50 treasures in total",
          progress: treasuresFound,
          target: 50,
          type: "achievement"
        },
        {
          id: "achieve-2",
          title: "Community Explorer",
          points: 150,
          icon: <Users className="w-5 h-5" />,
          description: "Help 20 other hunters find treasures",
          type: "achievement"
        },
        {
          id: "achieve-3",
          title: "Photo Collection",
          points: 100,
          icon: <Camera className="w-5 h-5" />,
          description: "Share 30 treasure location photos",
          progress: photosShared,
          target: 30,
          type: "achievement"
        }
      ]
    },
    {
      title: "Special Tasks",
      tasks: [
        {
          id: "special-1",
          title: "Navigation Master",
          points: "Max 50",
          icon: <Navigation className="w-5 h-5" />,
          description: "Complete treasure hunts with perfect navigation scores",
          type: "special"
        },
        {
          id: "special-2",
          title: "Community Star",
          points: "Max 100",
          icon: <Star className="w-5 h-5" />,
          description: "Get featured in weekly top hunters list",
          type: "special"
        }
      ]
    }
  ];

  const handleConnectWallet = async () => {
    try {
      // Replace this with your actual wallet connection logic
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWalletConnected(true);
        setCompletedTasks([...completedTasks, "base-1"]);
        
        const responseMessage = {
          type: 'success',
          content: 'Wallet connected successfully!'
        };
        // Add notification logic here if needed
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      // Add error notification logic here if needed
    }
  };

  const handleTaskComplete = (taskId) => {
    if (!completedTasks.includes(taskId)) {
      setCompletedTasks([...completedTasks, taskId]);
      
      // Update progress for achievement tasks
      if (taskId.startsWith('achieve')) {
        switch (taskId) {
          case 'achieve-1':
            setTreasuresFound(prev => Math.min(prev + 1, 50));
            break;
          case 'achieve-3':
            setPhotosShared(prev => Math.min(prev + 1, 30));
            break;
          default:
            break;
        }
      }
    }
  };

  const handleDailyTask = (taskId) => {
    const today = new Date().toISOString().split('T')[0];
    
    if (dailyTasks[taskId] !== today) {
      setDailyTasks({
        ...dailyTasks,
        [taskId]: today
      });

      // Add points or other rewards logic here
      switch (taskId) {
        case 'daily-1':
          // Handle daily check-in
          break;
        case 'daily-2':
          setTreasuresFound(prev => Math.min(prev + 3, 50)); // Count towards achievement
          break;
        case 'daily-3':
          setPhotosShared(prev => Math.min(prev + 1, 30)); // Count towards achievement
          break;
        default:
          break;
      }
    }
  };

  const isDailyTaskCompleted = (taskId) => {
    const today = new Date().toISOString().split('T')[0];
    return dailyTasks[taskId] === today;
  };

  const renderTaskCard = (task) => {
    const isCompleted = task.type === "daily" 
      ? isDailyTaskCompleted(task.id)
      : completedTasks.includes(task.id);
    const isDisabled = !walletConnected && task.id !== "base-1";

    return (
      <TaskCard key={task.id} isCompleted={isCompleted}>
        <TaskContent>
          <TaskInfo>
            <IconWrapper>
              {task.icon}
            </IconWrapper>
            <TaskDetails>
              <TaskTitle>{task.title}</TaskTitle>
              <TaskDescription>{task.description}</TaskDescription>
              {task.progress !== undefined && (
                <ProgressContainer>
                  <ProgressBar>
                    <ProgressFill value={(task.progress / task.target) * 100} />
                  </ProgressBar>
                  <ProgressText>
                    {task.progress}/{task.target}
                  </ProgressText>
                </ProgressContainer>
              )}
            </TaskDetails>
          </TaskInfo>
          <TaskActions>
            <Badge>{task.points} Points</Badge>
            <Button
              onClick={() => {
                if (task.id === "base-1") handleConnectWallet();
                else if (task.type === "daily") handleDailyTask(task.id);
                else handleTaskComplete(task.id);
              }}
              disabled={isDisabled || isCompleted || 
                (task.type === "achievement" && 
                 task.progress !== undefined && 
                 task.progress < task.target)}
            >
              {task.id === "base-1" && isCompleted ? 'Wallet Connected' :
               task.type === "daily" && isCompleted ? 'Completed Today' :
               task.type === "achievement" && isCompleted ? 'Achieved' :
               isCompleted ? 'Completed' : 'Complete Task'}
            </Button>
          </TaskActions>
        </TaskContent>
      </TaskCard>
    );
  };

  // Optional: Add useEffect for loading saved state
  useEffect(() => {
    // Load saved state from localStorage or your backend
    const savedState = localStorage.getItem('airdropTasks');
    if (savedState) {
      const { 
        completedTasks: saved, 
        dailyTasks: savedDaily,
        treasuresFound: savedTreasures,
        photosShared: savedPhotos
      } = JSON.parse(savedState);
      
      setCompletedTasks(saved || []);
      setDailyTasks(savedDaily || {});
      setTreasuresFound(savedTreasures || 0);
      setPhotosShared(savedPhotos || 0);
    }
  }, []);

  // Optional: Add useEffect for saving state
  useEffect(() => {
    // Save state to localStorage or your backend
    localStorage.setItem('airdropTasks', JSON.stringify({
      completedTasks,
      dailyTasks,
      treasuresFound,
      photosShared
    }));
  }, [completedTasks, dailyTasks, treasuresFound, photosShared]);

  return (
    <Container>
      <StyledCard>
        <CardHeader>
          <CardTitle>
            <Gift size={24} />
            Treasure Hunter Airdrop Tasks
          </CardTitle>
        </CardHeader>
        <CardContent>
          {taskGroups.map((group, index) => (
            <TaskGroup key={index}>
              <GroupTitle>
                <Clock size={20} />
                {group.title}
              </GroupTitle>
              <div>
                {group.tasks.map(task => renderTaskCard(task))}
              </div>
            </TaskGroup>
          ))}
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default AirdropTasks; 