import { motion, useMotionValue, useTransform } from "framer-motion";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Phase1 from "../../assets/images/phase1.png";
import Phase2 from "../../assets/images/phase2.png";
import Phase3 from "../../assets/images/phase3.png";
import Phase4 from "../../assets/images/phase4.png";
import Phase5 from "../../assets/images/phase5.png";

import { ShoesDetails } from "./Shoedetail";

const CardWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: column;
  font-family:'Press Start 2P', cursive;
  
  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
    padding: 2rem 0;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  margin: 0 auto;
  position: relative;
  perspective: 2000px;
  overflow: hidden;
  
  @media (max-width: 1024px) {
    width: 90%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    width: 95%;
    flex-direction: column;
    perspective: none;
    overflow: visible;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0;
  width: 80%;
  height: 80%;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  transform: translateX(calc(${props => props.currentIndex * -100}% / 3));
  
  @media (max-width: 1024px) {
    width: 90%;
    padding: 15px 0;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 30px;
    transform: none;
    transform-style: flat;
    padding: 20px 10px;
  }
`;

const CardContainer = styled(motion.div)`
  min-width: calc(100% / 3 - 14px);
  flex: 0 0 calc(100% / 3 - 14px);
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 2px 7px 1px rgba(31, 31, 31, 0.2);
  background-color: #ffffff;
  color: #fff;
  position: relative;
  cursor: grab;
  margin-right: 20px;
  overflow: hidden;
  
  @media (max-width: 768px) {
    min-width: 100%;
    flex: 0 0 100%;
    margin-right: 0;
    height: auto;
    min-height: 500px;
    cursor: default;
  }
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  border-top-right-radius: 25px;
`;

const Circle = styled.div`
  position: absolute;
  height:400px;
  width:400px;
  top: -5%;
  right: -20%;
  z-index: 5;
  background-color: #fbbe01;
  border-radius: 50%;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
  max-height: 180px;
  box-sizing: border-box;
  padding: 20px;
  margin: 0;
  position: relative;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 100%;
    min-height:200px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 1em;
`;

const NikeText = styled.h1`
  color: #000000;
  text-transform: uppercase;
  margin: 0;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 300;
  font-family:'Press Start 2P', cursive;
  width:50%;
  text-align: center;
  
  @media (max-width: 1024px) {
    font-size: 1.75rem;
    width: 70%;
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
    width: 90%;
  }
`;

const ShoesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Shoes = styled(motion.div)`
  min-width: calc(100% / 3 - 14px);
  flex: 0 0 calc(100% / 3 - 14px);
  margin-right: 20px;
  z-index: 99;
  user-select: none;
  margin-right: 15em;
  margin-top: 1em;
  
  img {
    width: 120%;
    height: auto;
    user-select: none;
  }
  
  @media (max-width: 1024px) {
    margin-top: 3em;
    
    img {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    margin-top: 2em;
    margin-right: 0;
  }
`;

const SliderButton = styled.button`
  background: #fbbe01;
  color: white;
  border: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:'Press Start 2P', cursive;
  margin: 0 50px;
  transition: all 0.3s ease;
  z-index: 999;
  
  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
    font-size: 30px;
    margin: 0 20px;
  }
  
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 25px;
    margin: 0 10px;
    display: none;
  }

  &:hover {
    background: ${props => props.disabled ? '#fbbe01' : '#e0a800'};
  }
`;

const CardInner = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
`;

const CardFront = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
`;

const CardBack = styled.div`
    position: absolute;
    width: 80%;
    height: 80%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 20px;
    border-radius: 25px;

  
`;

const MoreDetailButton = styled.button`
    padding: 10px 20px;
    background-color: #fbbe01;
    color: #000;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Press Start 2P', cursive;
    font-size: 12px;
    margin-top: 10px;
    position: relative;
    transition: all 0.2s ease;
    
    /* 3D effect with multiple shadows */
    box-shadow: 
        0 2px 0 #e0a800,
        0 4px 0 #c79400,
        0 6px 0 #b38600,
        0 8px 6px rgba(0, 0, 0, 0.3);
    
    /* Transform on hover */
    &:hover {
        transform: translateY(2px);
        box-shadow: 
            0 1px 0 #e0a800,
            0 2px 0 #c79400,
            0 4px 0 #b38600,
            0 6px 6px rgba(0, 0, 0, 0.3);
    }
    
    /* Transform on active/click */
    &:active {
        transform: translateY(4px);
        box-shadow: 
            0 1px 0 #e0a800,
            0 2px 0 #c79400,
            0 4px 4px rgba(0, 0, 0, 0.3);
    }
    
    @media (max-width: 768px) {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
`;

const HighlightedText = styled.span`
    color: #fbbe01;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Description = styled.p`
    color: #000;
    font-size: 12px;
    margin: 8px 0;
    font-family: 'Press Start 2P', cursive;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    
    @media (max-width: 768px) {
        font-size: 10px;
        line-height: 2;
    }
`;
const Title = styled.h2`
    font-size: 5rem;
  font-weight: bold;
  text-align: center;
  
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const roadmapData = [
    {
        quarter: "Q4 2024",
        phase: "Phase 1: Foundation",
        status: "Accomplished",
        colorstatus: "#2196f3",
        img: Phase1,
        tasks: [
            "Game Development",
            "AI Agent Development",
        ],
        description: [
            "1. Treasure placement & find",
            "2. AI Agent which can chat with the users and controls the functions of the Dapp",
          ],
    },
    {
        quarter: "Q1 2025",
        phase: "Phase 2: Launch",
        status: "In Progress",
        colorstatus: "#ff9800",
        img: Phase2,
        tasks: [
            "Web App Release for beta testing",
            "AI Agent for the disabled and elderly to automatically mint and sell the NFTs",
            "Digital/voucher ecosystem development",
            "Attending the Coindesk Genesis Hackathon 2025, HongKong"
        ],
        description: ["1. Web App Release in February for beta testing", "2. AI Agent to automatically mint and price the popular items into NFTs", "3. Virtual treasures development for merchants to issue vouchers"]
    },
    {
        quarter: "Q2 2025",
        phase: "Phase 3: Expansion",
        status: "Planning",
        colorstatus: "#f44336",
        img: Phase3,
        tasks: [
            "Partnership Programs",
            "Smart Contract Development and Deployment",
            "Tokenomics Design"
        ],
        description: ["1. Expend the partnership with the merchants", "2. Smart Contract Development and Deployment", "3. Tokenomics Design and lock-up mechanism"]
    },
    {
        quarter: "Q3 2025",
        phase: "Phase 4: Scaling",
        status: "Full Launch",
        colorstatus: "#c62828",
        img: Phase4,
        tasks: [
            "Airdrop to the community for early adopters",
            "Play & Help to earn kicks off"
        ],
        description: ["Coming Soon..."]
    },
    {
        quarter: "Q4 2025",
        phase: "Phase 5: Evolution",
        status: "Hoping",
        colorstatus: "#c62828",
        img: Phase5,
        tasks: [
            "GameFi + SocialFi integration",
            "Social Media Platform Launch",
            "DAO Governance Launch"
        ],
        description: ["Coming Soon..."]
    }
];

// Add this custom hook
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

// Create a new Card component
const Card = ({ data }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const isMobile = useIsMobile();
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  return (
    <CardContainer
      style={{ 
        x: isMobile ? 0 : x,
        y: isMobile ? 0 : y,
        rotateX: isMobile ? 0 : rotateX,
        rotateY: isMobile ? 0 : rotateY,
        z: 100 
      }}
      drag={!isMobile && !isFlipped}
      dragElastic={0.16}
      dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
      whileTap={{ cursor: isMobile ? "default" : "grabbing" }}
    >
      <CardInner isFlipped={isFlipped}>
        <CardFront>
          <TopContainer>
            <CircleWrapper>
              <Circle />
            </CircleWrapper>
            <ShoesWrapper>
              <Shoes
                style={{ x, y, rotateX, rotateY, rotate: "-10deg", z: 100000 }}
                drag={!isFlipped}
                dragElastic={0.12}
                whileTap={{ cursor: "grabbing" }}
              >
                <img src={data.img} alt={data.phase} />
              </Shoes>
            </ShoesWrapper>
            <NikeText>{data.phase}</NikeText>
          </TopContainer>
          <BottomContainer>
            <ShoesDetails 
              quarter={data.quarter}
              status={data.status}
              tasks={data.tasks}
              colorstatus={data.colorstatus}
              description={data.description}
            />
            <MoreDetailButton onClick={() => setIsFlipped(true)}>
              More Details
            </MoreDetailButton>
          </BottomContainer>
        </CardFront>
        <CardBack>
          <NikeText style={{ color: '#000', marginBottom: '50px', width: '100%' }}>
            {data.phase}
          </NikeText>
          {data.description.map((desc, index) => (
            <Description key={index}>
              {Array.isArray(desc) ? desc : desc}
            </Description>
          ))}
          <MoreDetailButton 
            onClick={() => setIsFlipped(false)}
            style={{ marginTop: 'auto' }}
          >
            Back
          </MoreDetailButton>
        </CardBack>
      </CardInner>
    </CardContainer>
  );
};

// Update the NikeCard component to use the new Card component
export function NikeCard(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useIsMobile();
  const maxIndex = roadmapData.length - 3;

  const nextSlide = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, maxIndex));
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  return (
    <CardWrapper id="roadmap">
      <Title>Roadmap</Title>
      <SliderContainer>
        {!isMobile && (
          <SliderButton 
            onClick={prevSlide} 
            disabled={currentIndex === 0}
            style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
          >
            &lt;
          </SliderButton>
        )}
        <CardsContainer currentIndex={isMobile ? 0 : currentIndex}>
          {roadmapData.map((data, index) => (
            <Card key={index} data={data} />
          ))}
        </CardsContainer>
        {!isMobile && (
          <SliderButton 
            onClick={nextSlide}
            disabled={currentIndex >= maxIndex}
            style={{ opacity: currentIndex >= maxIndex ? 0.5 : 1 }}
          >
            &gt;
          </SliderButton>
        )}
      </SliderContainer>
    </CardWrapper>
  );
}