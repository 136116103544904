import styled from 'styled-components';
import AgentArchitecture from '../../assets/images/Agentarchitecture.svg';

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  /* Add a subtle shadow for better visibility */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  
  /* Responsive breakpoints */
  @media (max-width: 1024px) {
    max-width: 90%;
  }
  
  @media (max-width: 768px) {
    max-width: 95%;
  }
`;

const Heading = styled.h1`
   font-size: 5rem;
  font-weight: bold;
  text-align: center;
  
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2rem;
  }
  text-align: center;
  margin-bottom: 20px;
   font-family: 'Press Start 2P', cursive;
`;

const CityArt = () => {
  return (
    <ImageContainer>
      <Heading>AI Agent Architecture Design</Heading>
      <StyledImage 
        src={AgentArchitecture} 
        alt="Agent Architecture Diagram"
      />
    </ImageContainer>
  );
};

export default CityArt;
