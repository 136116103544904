import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CathAssistant from '../components/CathAssistant';

const PageContainer = styled.div`
  display: flex;
  gap: 2rem;
  padding: 2rem;
  height: calc(100vh - 4rem);
  background: #f9fafb;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }
`;

const ChatSection = styled.div`
  flex: 1;
  min-width: 0; // Prevent flex item from overflowing
`;

const MapSection = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    height: 400px;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const AIAssistant = () => {
  const [map, setMap] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2FodW50ZXIiLCJhIjoiY20zcDk3MWptMGQ2OTJyb2FjcWVodXcwbyJ9.KpVXU9HKAb6zv5As2_1BuQ';

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([longitude, latitude]);
          
          const newMap = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [longitude, latitude],
            zoom: 15,
            maxZoom: 20,
            minZoom: 10
          });

          // Add navigation and other controls
          newMap.addControl(new mapboxgl.NavigationControl());
          newMap.addControl(new mapboxgl.ScaleControl());
          newMap.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true,
              showUserHeading: true
            })
          );

          // Wait for map to load before adding layers
          newMap.on('load', () => {
            // Add 3D buildings layer
            newMap.addLayer({
              'id': '3d-buildings',
              'source': 'composite',
              'source-layer': 'building',
              'filter': ['==', 'extrude', 'true'],
              'type': 'fill-extrusion',
              'minzoom': 15,
              'paint': {
                'fill-extrusion-color': '#aaa',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height']
                ],
                'fill-extrusion-opacity': 0.6
              }
            });

            // Add POI labels
            newMap.addLayer({
              'id': 'poi-labels',
              'type': 'symbol',
              'source': 'composite',
              'source-layer': 'poi_label',
              'layout': {
                'text-field': ['get', 'name'],
                'text-size': 12,
                'text-anchor': 'top',
                'text-offset': [0, 0.6]
              },
              'paint': {
                'text-color': '#666',
                'text-halo-color': '#fff',
                'text-halo-width': 1
              }
            });

            // Enhance street labels
            newMap.setLayoutProperty('road-label', 'text-size', [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              8,
              20,
              16
            ]);
          });

          // Add user location marker
          new mapboxgl.Marker({
            color: '#FF0000',
            scale: 0.8
          })
            .setLngLat([longitude, latitude])
            .setPopup(new mapboxgl.Popup().setHTML('You are here'))
            .addTo(newMap);

          setMap(newMap);
        },
        (error) => {
          console.error('Error getting location:', error);
          const defaultLocation = [151.211084, -33.856471];
          
          const newMap = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: defaultLocation,
            zoom: 15
          });

          // Add same controls for default location
          newMap.addControl(new mapboxgl.NavigationControl());
          newMap.addControl(new mapboxgl.ScaleControl());
          
          setMap(newMap);
        }
      );
    }

    return () => {
      if (map) map.remove();
    };
  }, []);

  // Add event listener for map interactions
  useEffect(() => {
    if (!map) return;

    // Add hover effect for POIs
    map.on('mouseenter', 'poi-labels', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    map.on('mouseleave', 'poi-labels', () => {
      map.getCanvas().style.cursor = '';
    });

    // Add click event for POIs
    map.on('click', 'poi-labels', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const name = e.features[0].properties.name;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`<h3>${name}</h3>`)
        .addTo(map);
    });
  }, [map]);

  return (
    <PageContainer>
      <ChatSection>
        <CathAssistant />
      </ChatSection>
      {/* <MapSection>
        <MapContainer id="map" />
      </MapSection> */}
    </PageContainer>
  );
};

export default AIAssistant; 