import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Keyframe for shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

// Styled Components
const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "#e5e7eb"};
  border-radius: ${(props) => props.borderRadius || 8}px;
  overflow: hidden;
  height: ${(props) => props.height || 8}px;
  position: relative;
  opacity: 0.8;

`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: ${(props) => props.color || "#3b82f6"};
  width: ${(props) => props.progress || 0}%;
  border-radius: ${(props) => props.borderRadius || 8}px;
  transition: ${(props) =>
    props.animated ? `width ${props.duration || 1000}ms ease-out` : "none"};
  position: relative;
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${(props) => props.shimmerColor || "rgba(255, 255, 255, 0.5)"} 50%,
    transparent 100%
  );
  background-size: 200% 100%;
  animation: ${(props) => (props.animated ? shimmer : "none")}
    ${(props) => props.shimmerDuration || 2}s infinite linear;
`;

const Title = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-family: "Press Start 2P", cursive;
`;

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ProgressPercentage = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
`;

// ProgressBar Component
const ProgressBar = ({
  progress = 0,
  height = 8,
  color = "#0F9D58",
  backgroundColor = "#ffffff",
  shimmerColor = "rgba(255, 255, 255, 0.5)",
  animated = true,
  duration = 1000,
  borderRadius = 8,
  shimmerDuration = 2,
}) => (
  <ProgressBarContainer
    height={height}
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    role="progressbar"
    aria-valuenow={progress}
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <ProgressFill
      progress={progress}
      color={color}
      animated={animated}
      duration={duration}
      borderRadius={borderRadius}
    >
      <ShimmerEffect
        animated={animated}
        shimmerColor={shimmerColor}
        shimmerDuration={shimmerDuration}
      />
    </ProgressFill>
    {/* <ProgressPercentage>{progress}%</ProgressPercentage> */}
  </ProgressBarContainer>
);

// Main Progress Component
const Progress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 80) {
          clearInterval(interval); // Stop incrementing at 40%
          return 80;
        }
        return prev + 5; // Increment by 5% every 0.5 seconds
      });
    }, 500);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <Wrapper>
      <Title>BUIDL PROGRESS:</Title>
      <ProgressBar
        progress={progress}
        
        height={30}
        color="#C3ECB2"
        backgroundColor="#ffffff"
        shimmerColor="rgba(255, 255, 255, 0.3)"
        animated={true}
        duration={800}
        shimmerDuration={1.5}
      />
    </Wrapper>
  );
};

export default Progress;